import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
//import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
//import Particles from '../../Agency/Particle';
//import BannerWrapper, { DiscountLabel } from './bannerSection.style';
import BannerWrapper from './bannerSection.style';
//import TextWrapper from './bannerSection.style';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      {/*<Button title="GET STARTED" {...btnStyle} />
       <Button
        title="WATCH WORKS"
        variant="textButton"
        icon={<i className="flaticon-next" />}
        {...outlineBtnStyle}
      /> */}
    </Fragment>
  );
  return (
    <BannerWrapper>
      {/*<Particles />*/}
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <h1 style={{fontSize: "40px", fontWeight: "500", margin: "0", color: "#3D636C"}}>The Importance of Biometric Screenings for Employee Health</h1>
            <FeatureBlock
              title={
                <Heading
                  content=""
                  {...title}
                />
              }
              description={
                <Fragment>
                <Text
                  content="Every corporate organization understands the role of screening programs. Employees can greatly benefit from screening because the process increases productivity and good health through reduced health risks. According to the Centers for Disease Control and Prevention (CDC), biometric screening refers to the measurement of physical characteristics like weight, height, blood pressure, blood glucose, height, and body mass index. The screening process offers basic information that helps in the measurement of health data to assess the risks of health issues and identify the opportunities for improvements in employee health."
                  {...description}
                />
                </Fragment>
              }
              button={<ButtonGroup />}
            />                              
          </Box>
        </Box>   
        <Fragment>
          <div id="article-content">
            <Heading as="h2" content="Benefits of Biometric Screenings"/>
            <Text style={{fontWeight: "500"}} content="There are several benefits associated with a healthcare screening process. These include:"></Text><br/>
        
            <Heading as="h3" content="Initiates Health Awareness"/>
            
            <p>Health issues do not just develop overnight. 
            Worksite screening helps to identify the health issues affecting the employees, 
            thus, <a href="https://www.cincyhealthworks.com/2019/07/09/why-your-annual-physical-is-better-after-youve-had-a-worksite-biometric-screening/" target="new">creating health awareness</a>. 
            If three or more measurements exceed the average range, employees may be considered at risk 
            for heart conditions, etc. Armed with the knowledge of potential health risks, employees 
            are able to make better decisions about their health.</p><br/>
            
            <Heading as="h3" content="Helps Alleviate Healthcare Costs"/>
            <p>
              Medical screening decreases employees’ rising health plan costs and can lower insurance premiums. 
              The CDC announced <a href="https://www.midlandhealth.com/biometric-screening/lower-health-costs" target="new">75% of the nation’s</a> healthcare spending is toward people with chronic conditions 
              like heart disease, diabetes, etc. A 2018 study also revealed that increased glucose and cholesterol 
              levels are the leading causes of rising healthcare costs at the workplace. Fortunately, most chronic 
              conditions can be prevented or minimized if caught early. Medical screenings could potentially save 
              employees <a href="https://www.healthstatinc.com/blog/proven-onsite-biometric-screenings-save-money" target="new">millions of dollars</a> if they undergo routine screening of the lungs, heart, kidney, white 
              and red blood cells, thyroid, pancreas, and liver.</p><br/>

            <Heading as="h3" content="Offer More Time to Discuss Health Plan"/>
            <Text content="A worksite screening can help employees make their annual physicals more productive. The process enables every employee to discuss their health status over time and consider a comprehensive health plan. In addition, instead of spending more time ordering tests, screening promotes good relationships with doctors, particularly employees that may be timid."/><br/>

            <Heading as="h3" content="Promotes Valuable Physician Coaching"/>
            <p>
              When employees are able to bring their health screening and blood tests to their frequent health visits, 
              they make the appointment process more comprehensive. This gives your employees the opportunity to receive 
              a list of <a href="https://www.cincyhealthworks.com/2019/07/09/why-your-annual-physical-is-better-after-youve-had-a-worksite-biometric-screening/">personalized recommendations</a> 
              from their doctors on how to lower their risks and be given a health coach.
            </p><br/>

            <Heading as="h3" content="Creates Urgency for Healthy Living"/>
            <Text content="Health screening allows employees to receive health data immediately after screening, which can inform them of their overall wellness and any lifestyle changes that need immediate attention. Physicians can also help identify any risk factors or major health concerns that the data presents so that employees are more likely to take action towards better living."/><br/>
            <p>
              While physicals are important and often covered by a company’s insurance, individuals do not always go annually, 
              as they should. A screening process at work increases <a href="https://www.wellright.com/blog/should-your-wellness-program-include-biometric-health-screening" target="new">employee health awareness</a> to your employees that they are 
              in more need of a checkup than they thought, a scenario that promotes healthy life choices.<br/>
            </p>
            <Heading as="h3" content="Enhances health data availability"/>
            <Text content="While companies cannot see the individual’s personal screening results, they make the information accessible to employees. They also receive the aggregate results that indicate the overall health of the workforce. The overall health information of the workforce empowers organizations to decide which health programs to offer. If many staff are showing prediabetes or hypertension symptoms, for example, the company can offer nutrition classes regularly."/><br/>

            <Heading as="h3" content="Enables Employers to Remain Competitive"/>
            <p>
            The corporate significance of screening is in the numbers. 
            Offering screening to employees enables an organization to have 
            an aggregate data of their workforce in order to understand the 
            potential costs of health risks. This data does not, however, 
            offer a comprehensive analysis of immediate costs, but what the 
            organization should expect in future. The aggregate data not 
            only equips the organization with needed healthcare data, 
            but also helps in coming up with a culture that promotes health and wellness.
            </p><br/>
            <p>
              Organizations that rely on screening data to offer <a href="https://telligen.com/recent-news/the-value-of-biometric-screenings/" target="new">on-demand wellness 
              programs</a> are able to witness positive return on investment (ROI) over time. 
              The increased ROI is associated with high productivity and reduced costs of
               illnesses among staff. Additionally, studies indicate that improved resilience always becomes part of the comprehensive wellness program.
            </p><br/>

            <Heading as="h2" content="Features of a Healthcare Screening Program"/>
            <Text style={{fontWeight: "500"}} content="The process for screening includes:"></Text><br/>

            <Heading as="h3" content="Appointment Scheduling"/>
            <Text content="The corporate organization (employer) first needs to schedule an appointment with a chosen healthcare provider for employees to access the screening services."/><br/>

            <Heading as="h3" content="Choosing the Screening Option"/>
            <Text content="Employees will choose their preferred screening option, depending on their availability. Offsite screening is always the best option for remote employees or those who are unable to attend an onsite procedure. An offsite option will include a lab voucher, a test kit, and a physician form."/><br/>

            <Heading as="h3" content="Secure Data Capture"/>
            <p>
              The screening provider then captures the screening data electronically using 
              <a href="https://uswellness.com/Biometric-Health-Screening-Services" target="new">electronic data capture (EDC)</a> technology.
            </p><br/>

            <Heading as="h3" content="Screening Results and Education"/>
            <Text content="The screening results are kept confidential to ensure that the health data of employees are kept confidential. The healthcare provider informs employees of the measurements, including those that are above normal range and offer coaching, education, and recommendations to lower the risk."/><br/>

            <Heading as="h3" content="Reporting"/>
            <Text content="The healthcare provider then offers de-identified data on the health of the participants. This stage also involves sharing employee engagement statistics and tracking of incentives to evaluate the success of the screening program."/><br/>

            <Heading as="h2" content="Ways to Administer Biometric Screening"/>
            <Text style={{fontWeight: "500"}} content="The three ways to administer health screenings to employees:"></Text><br/>

            <Heading as="h3" content="Onsite Screening"/>
            <p>
              This is a screening process in which a health vendor comes in at the workplace to 
              <a href="https://www.cornerstoneondemand.com/rework/4-facts-about-biometric-screenings-you-need-know" target="new">collect biometric data</a> for all the employees at once. 
              This approach is usually possible when the employees are situated in one geographical location and can easily present themselves for the screening. 
              The screening method costs between <a href="https://www.healthstatinc.com/blog/proven-onsite-biometric-screenings-save-money" target="new">$45 and $70</a>.
            </p><br/>
            
            <Text content="An onsite screening involves full lipid panel, blood glucose, blood pressure measurement, results review, immediate referral for health response, and reporting. The screening process maximizes the participation of employees while minimizing the loss of work productivity and time."/><br/>
            <Text style={{fontWeight: "500"}} content="Benefits of onsite screening:"></Text>
            <p>
              &bull; An <a href="https://onsitehealthdiagnostics.com/biometric-screenings/" target="new">extremely convenient approach</a> — The screening process can be done while employees are on their break, without taking work leave.
            </p><br/>
            <Text content="&bull; Onsite screening works best for corporations with large offices because it is cost-effective."/><br/>
            <p>
              &bull; Employees are able to access <a href="https://www.careatc.com/ehs/what-is-a-biometric-screening-and-why-do-your-employees-need-one" target="new">immediate health coaching</a> once they get their screening results.
            </p><br/>

            <Heading as="h3" content="Offsite Screening"/>
            <Text content="In this screening approach, participants visit their care providers or designated healthcare center for a blood draw or biometrics screening. Similar to onsite screening, this method includes lab results in the automatic data integration and reporting. "/><br/>
            <Text style={{fontWeight: "500"}} content="Offsite screening can be conducted in 3 options:"/><br/>
            <Text content="&bull; Lab voucher— In this approach, healthcare providers partner with national labs to provide blood screening services. Employees can register online with the healthcare providers to generate a map to locate the lab and take screening tests. The results are then emailed directly to the employees. "/><br/>
            <Text content="&bull; Physician Derived Results—In this case, employees may visit their primary care physicians to obtain their screening results. The screening forms are accessed through a registration portal, downloaded, and taken to the physician where results of the screening are recorded and returned to healthcare providers through secure fax. "/><br/>
            <Text content="&bull; Home test kit— This approach gives employees a private option of administering medical screening using a home test kit. The participants register for a kit, which is then emailed directly to them. They then conduct a fingerstick test and mail back the kit to their healthcare practitioners. "/><br/>
            
            <Text style={{fontWeight: "500"}} content="Benefits of offsite screening:"/>
            
            <Text content="&bull; Offsite screening is a flexible method as it allows companies with remote offices and employees to obtain the screening services."/><br/>
            <Text content="&bull; The screening method enables organizations to work collaboratively with screening partners and labs to collect the employee results. Lab-corp and local pharmacies such as CVS also offer offsite screening services."/><br/>
            

            <Heading as="h2" content="Physician Fax Form"/>
            <Text content="Employees may also use physician fax forms to get screening tests from their personal physicians. In this case, participants go for screening then fill in medical information in a fax form and forward the data to their provider. The provider in turn, completes the biometric and body measurement results and signs the form."/><br/>
            <Text content="This approach provides flexibility as employees are able to take the screening test whenever they have the chance. It is also considered cost effective because most medical plans include one physical every year. Also, the screening method encourages a relationship with primary care physicians."/><br/>

            <Heading as="h2" content="Screening Measurements"/>
            <Text style={{fontWeight: "500"}} content="During medical screening, the following measurements will be taken:"/>
            <Text content="&bull; Blood Pressure"/>
            <Text content="&bull; Cholesterol"/>
            <Text content="&bull; Triglyceride levels"/>
            <Text content="&bull; HDL and LDL levels"/>
            <Text content="&bull; Blood Sugar"/>
            <Text content="&bull; Height and Weight Measurements"/>
            <Text content="&bull; Tobacco usage"/>
            <p>
              &bull; <a href="https://www.concentra.com/resource-center/articles/how-your-employees-benefit-from-biometric-screenings/" target="new">Body Mass Index (BMI)</a>
            </p><br/>

            <Text content="In summary, healthcare screening forms a significant aspect of every corporate organization. It helps employers to assess the potential health risk costs as well as come up with wellness programs that suit the needs of employees. As screening provides employees with health data and essential education tools, the population is able to predict their health concerns and work around them for their well-being. Overall, employers and employees get to increase productivity and remain motivated because of better health."/><br/>

          </div>  
        </Fragment>
      </Container>
      
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '60%', '50%'], //<480, <768, >768, >990
  },
  title: {
    fontSize: ['16px', '24px', '32px', '45px'],
    fontWeight: '300',
    color: '#3D636C',
    //letterSpacing: '-0.025em',
    letterSpacing: 0,
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: ['14px','16px','20px'],
    color: '#3D636C',
    lineHeight: '1.5',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '14px',
    fontWeight: '300',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#10AC84',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: '14px',
    color: '#0f2137',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
