import styled from 'styled-components';
import { themeGet } from 'styled-system';
import BannerBG from 'common/src/assets/image/archerhealth/banner/Hero-Article-Biometric-Screenings.png';

const BannerWrapper = styled.section`
  padding-top: 210px;
  padding-bottom: 250px;
  background-image: url(${BannerBG});
  background-size: 50%;
  background-position: right 100px;
  background-repeat: no-repeat;
  overflow: hidden;

  @media only screen and (min-width: 1950px) {
    background-size: 35%;
  }

  @media only screen and (min-width: 1367px) {
    min-height: 100vh;
  }

  @media (max-width: 990px) {
    padding-top: 150px;
    padding-bottom: 100px;
    background-size: 40%;
  }

  @media only screen and (max-width: 767px) {
    background: none;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 480px) {
    background: none;
    padding-top: 130px;
    padding-bottom: 20px;
  }

  .particle {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 990px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .row {
    position: relative;
    z-index: 1;
  }
  .button__wrapper {
    margin-top: 40px;
    .reusecore__button {
      &:first-child {
        transition: all 0.3s ease;
        &:hover {
          box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
        }
      }
    }
  }

  #article-content {
    width: 800px;

    a {
      color: #18B8E8;
    }

  @media only screen and (max-width: 991px) {
    padding-left: 50px;
    h2 {
      font-size: 30px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
    margin-bottom: 60px;

    h2 {
      font-size: 22px;
    }
  }

    h2 {
      color: #3D636C;
      font-weight: 400;
      letter-spacing: -0.025em;
      line-height: 1.3;
      font-size: 32px;
  
      @media only screen and (min-width: 1441px) {
        margin-bottom: 25px;
      }
  
      @media only screen and (min-width: 1440px) {
        margin-bottom: 20px;
      }
  
      @media only screen and (min-width: 767px) {
        margin-bottom: 15px;
      }
    }

    h3 {
      color: #2CC36B;
      font-size: 28px;
      font-weight: normal;
    }
  
    p {
      margin-bottom: 30px;   
      font-size: 16px;
      font-weight: 400;
      color: #3D636C;
      line-height: 1.85;
      margin-bottom: 0;
      letter-spacing: 0;
    }
  }
`;

const DiscountLabel = styled.div`
  display: inline-block;
  border-radius: 4em;
  padding: 7px 25px;
  box-shadow: 0px 4px 50px 0px rgba(22, 53, 76, 0.08);
  margin-bottom: 30px;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media (max-width: 767px) {
    padding: 7px 10px;
  }
`;

export { DiscountLabel };


export default BannerWrapper;
